<template>
  <Connect class="connect_view">
    <ul class="list_connect">
      <li
        v-for="item in dataList"
        :key="item.requestCid || item.settlementCid"
        :class="{ disabled: isDisabled(item) }"
      >
        <span class="ico_account ico_connect_sm" />
        <button
          v-if="!isDisabled(item)"
          type="button"
          class="btn_connect"
          @click="$emit('onClickConnect', item)"
        >
          <span class="tit_connect">{{ titleKeyName ? item[titleKeyName] : item.title }}</span>
          <span class="ico_account ico_arr_connect">상세화면 보기</span>
        </button>
        <span v-else class="tit_connect">{{ titleKeyName ? item[titleKeyName] : item.title }}</span>
      </li>
    </ul>
  </Connect>
</template>

<script>
import Connect from "@/components/common/connect/Connect";

export default {
  name: "ConnectView",
  components: {
    Connect,
  },
  props: {
    dataList: Array,
    titleKeyName: String,
    disabledData: Object,
  },
  methods: {
    isDisabled(item) {
      const keyNm = Object.keys(this.disabledData)[0];
      const valueNm = Object.values(this.disabledData)[0];
      return item[keyNm] == valueNm;
    },
  },
};
</script>
